<template>
  <div class="photo--preview">
    <div class="first--col">
      <img width="100%" height="auto" :src="photoSource" class="photo--image input--margin" />
    </div>
    <div class="second--col">
      <div>{{ photo.fileName ? photo.fileName : photo.uuid }}</div>
      <div class="use--primary" v-if="!isFloorPlan && !noPrimary">
        <div class="check" :class="{ checked: photo.isPrimary }" @click="togglePrimary"></div>
        <input
          :id="`set-primary-${photo.uuid}`"
          :checked="photo.isPrimary"
          type="radio"
          class="radio--input"
          @click="togglePrimary"
        />
        <label class="radio--label" :for="`set-primary-${photo.uuid}`">{{
          $t('project.primaryPhoto')
        }}</label>
      </div>
      <div v-else-if="noPrimary"></div>
    </div>
    <div class="third--col">
      <img src="@/assets/img/icons/trash.svg" @click="removePhoto" class="trash--button" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'photo-preview',
  props: ['index', 'photo', 'length', 'isFloorPlan', 'noPrimary', 'removePhotoDispatch'],
  computed: {
    ...mapState({
      baseUrl: (state) => state.global.baseUrl,
    }),
    photoSource() {
      if (this.photo.src.search('/storage/') !== -1) {
        return this.baseUrl + this.photo.src;
      } else {
        return this.photo.src;
      }
    },
  },
  methods: {
    togglePrimary() {
      this.$store.commit('project/form/media/TOGGLE_PRIMARY_PHOTO', this.index);
    },
    removePhoto() {
      if (this.removePhotoDispatch) {
        this.$store.commit(this.removePhotoDispatch, this.index);
      }
    },
  },
};
</script>

<style scoped></style>
